<template>
  <div style="height: 80px"><Header /></div>
  <div class="elv-careers-main">
    <div class="elv-careers-main-content">
      <div class="elv-careers-main-content-body">
        <div class="elv-careers-main-content-body__title">Careers</div>
        <div class="elv-careers-main-content-body__title-des">
          Fresh university graduates are welcome to apply for some open positions.
        </div>
        <div class="elv-careers-main-content-body__top">
          <div class="elv-careers-main-content-body__top-title">{{ careers.title }}</div>
          <div class="elv-careers-main-content-body__top-content">
            <div
              v-for="(item, index) in careers.content"
              :key="index"
              class="elv-careers-main-content-body__top-content__item"
            >
              {{ item }}
            </div>
          </div>
          <div class="elv-careers-main-content-body__top-content">
            <div
              v-for="(item, index) in careers.requirements"
              :key="index + item"
              class="elv-careers-main-content-body__top-content__item"
            >
              {{ item }}
            </div>
          </div>
          <div class="elv-careers-main-content-body__top-address">{{ careers.address }}</div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>
<script lang="ts" setup>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

const careers = {
  title: 'Data Product Manager',
  content: [
    'Job content:',
    '1. Be responsible for the planning, design and implementation of Elven data platform related products.',
    '2. Propose and implement a revision plan for data quality problems according to business requirements.'
  ],
  requirements: [
    'Job requirements:',
    '1. Have practical experience in data warehouse, data governance, data analysis and data visualization (0-1 experience), be familiar with SQL, and have knowledge of data warehouse and BI system.',
    '2. Strong product owner awareness, good logical thinking, communication and coordination, learning and analysis, and implementation ability.'
  ],
  address: '邮箱：hr@elven.com'
}
</script>
<style lang="scss" scoped>
.elv-careers-main {
  width: 100%;

  .elv-careers-main-content {
    width: 1440px;
    margin: auto auto 80px auto;
    padding-top: 40px;

    .elv-careers-main-content-body {
      padding: 0 187px;

      .elv-careers-main-content-body__title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        color: $elv-theme-base-text-color;
      }

      .elv-careers-main-content-body__title-des {
        margin-top: 12px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: $elv-color-333333;
      }

      .elv-careers-main-content-body__top {
        margin-top: 40px;

        .elv-careers-main-content-body__top-title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          color: $elv-color-000000;
        }

        .elv-careers-main-content-body__top-content {
          margin-top: 16px;

          .elv-careers-main-content-body__top-content__item {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $elv-theme-text-color;
          }
        }

        .elv-careers-main-content-body__top-address {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $elv-color-838d95;
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
